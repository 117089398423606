<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import HomeTop from '../../../components/HomeTop'
import { downloadAction, getParams, getAction } from '@/command/netTool'
import apiTool from '@/command/apiTool'
import { Message } from 'ant-design-vue'

export default {
  name: 'settlementAuditIndexDetail',
  data() {
    return {
      ...api.command.getState(),
      courierData: [],
      orderItemListId: null,
      // 出金状态 1 二级商户出金成功 2 二级商户出金失败 3 二级商户出金异常
      cashOutStatus: [
        {
          statusName: '操作成功',
          status: 'success',
        },
        {
          statusName: '二级商户出金成功',
          status: 'success',
        },
        {
          statusName: '二级商户出金失败',
          status: 'error',
        },
        {
          statusName: '二级商户出金异常',
          status: 'warning',
        },
      ],
      //	结算状态 1 手动结算成功 2 手动结算失败 3 二级商户结算成功 4 二级商户结算失败 5 二级商户结算异常
      jsStatus: [
        {
          statusName: '操作成功',
          status: 'success',
        },
        {
          statusName: '手动结算成功',
          status: 'success',
        },
        {
          statusName: '手动结算失败',
          status: 'error',
        },
        {
          statusName: '二级商户结算成功',
          status: 'success',
        },
        {
          statusName: '二级商户结算失败',
          status: 'error',
        },
        {
          statusName: '二级商户结算异常',
          status: 'warning',
        },
      ],
      jsOrderObj: {
        jsAmount: 0,
        noJsAmount: 0,
        orderNum: 0,
        // 销售总额
        totalAmount: 0,
        unJsAmount: 0,
      },
      handJsState: 1,
    }
  },
  watch: {},
  mounted() {
    getAction('/api/farmConfig/detail').then((res) => {
      this.handJsState = res.data.handJsState
    })
    api.command.getList.call(this, {
      url: '/jsOrder/recordList',
      current: 1,
      paramsValue: {
        shopId: this.$route.query.shopId,
      },
    })
    this.initJsOrder()
  },
  methods: {
    initJsOrder() {
      getAction(`/jsOrder/mainBody/detail?shopId=${this.$route.query.shopId}`).then((result) => {
        this.jsOrderObj = result.data || {}
        this.$forceUpdate()
      })
    },
    getHomeTopDataConfig() {
      return [
        {
          name: '销售总额',
          value: `￥${(+this.jsOrderObj?.totalAmount || 0).toFixed(2)}`,
        },
        {
          name: '不结算金额',
          value: `￥${(+this.jsOrderObj?.noJsAmount || 0).toFixed(2)}`,
        },
        {
          name: '未结算金额',
          value: `￥${(+this.jsOrderObj?.unJsAmount || 0).toFixed(2)}`,
        },
        {
          name: '已结算金额',
          value: `￥${(+this.jsOrderObj?.jsAmount || 0).toFixed(2)}`,
        },
      ]
    },
    getHeader() {
      return [
        {
          name: '单号',
          type: 'input',
          key: 'orderSn',
          placeholder: '请输入单号',
        },
        {
          name: '时间',
          type: 'rangePicker',
          keys: ['startDate', 'endDate'],
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'orderSn',
          title: '单号',
          isId: true,
          align: 'left',
          sorter: (a, b) => a.orderSn - b.orderSn,
        },
        {
          dataIndex: 'productName',
          title: '出售商品',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                {records.jsOrderItemList.map((e) => (
                  <div class="product-order-item">
                    {e.productPic && <img src={e.productPic} />}
                    <div>{e.productName}</div>
                  </div>
                ))}
              </div>
            )
          },
        },
        {
          dataIndex: 'productQuantity',
          title: '数量',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-number">
                {records.jsOrderItemList.map((e) => {
                  return <div>{e.productQuantity}</div>
                })}
              </div>
            )
          },
        },
        {
          dataIndex: 'productPrice',
          title: '价格',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-number">
                {records.jsOrderItemList.map((e) => {
                  return <div>{e.productPrice}</div>
                })}
              </div>
            )
          },
        },
        {
          dataIndex: 'refundAmount',
          title: '退款金额',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div class="product-number">
                {records.jsOrderItemList.map((e) => {
                  return <div>{e.refundAmount}</div>
                })}
              </div>
            )
          },
        },
        {
          dataIndex: 'totalAmount',
          title: '总额',
          align: 'left',
        },
        {
          dataIndex: 'discountAmount',
          title: '优惠券',
          align: 'left',
        },
        {
          dataIndex: 'balanceAmount',
          title: '余额券',
          align: 'left',
        },
        {
          dataIndex: 'payAmount',
          title: '实付金额',
          align: 'left',
        },
        {
          dataIndex: 'payType',
          title: '支付方式',
          align: 'left',
          customRender: (text, records) => {
            let objText = {
              1: '支付宝',
              2: '微信',
              3: '农行APP',
              4: '0元付',
            }
            return <div>{objText[text]}</div>
          },
        },
        {
          dataIndex: 'fromType',
          title: '类型',
          align: 'left',
          customRender: (text, records) => {
            let objText = {
              1: '农家特产',
              2: '吃好喝好',
              3: '农舍民宿',
              4: '农事体验',
              5: '农事体验',
              6: '线下支付',
              7: '线下支付',
              12: '景点门票',
              13: '酒店民宿',
            }
            return <div>{objText[text]}</div>
          },
          onExport: (text, records) => {
            let objText = {
              1: '农家特产',
              2: '吃好喝好',
              3: '农舍民宿',
              4: '农事体验',
              5: '农事体验',
              6: '线下支付',
              7: '线下支付',
              12: '景点门票',
              13: '酒店民宿',
            }
            return `${objText[text]}`
          },
          filterMultiple: false,
          filters: [
            {
              text: '农家特产',
              value: '1',
            },
            {
              text: '吃好喝好',
              value: '2',
            },

            // {
            //   text: '农舍民宿',
            //   value: '3',
            // },
            {
              text: '农事体验',
              value: '4',
            },
            {
              text: '景点门票',
              value: '12',
            },
            {
              text: '酒店民宿',
              value: '13',
            },
            {
              text: '线下支付',
              value: '5',
            },
          ],
        },
        // {
        //   dataIndex: 'payType',
        //   title: '支付方式',
        //   align: 'left',
        //   filters: [
        //     {
        //       text: '未支付',
        //       value: '0'
        //     },
        //     {
        //       text: '支付宝',
        //       value: '1'
        //     },
        //     {
        //       text: '微信',
        //       value: '2'
        //     },
        //     {
        //       text: '农行支付',
        //       value: '3'
        //     },
        //     {
        //       text: '0元付',
        //       value: '4'
        //     }
        //   ],
        //   filterMultiple: false,
        //   customRender: (text, records) => {
        //     return { '1': '支付宝', '2': '微信', '3': '农行支付', '4': '0元付' }[text] || '未支付'
        //   },
        //   onExport: (text, records) => {
        //     return { '1': '支付宝', '2': '微信', '3': '农行支付', '4': '0元付' }[text] || '未支付'
        //   }
        // },
        // {
        //   dataIndex: 'jsType',
        //   title: '结算方式',
        //   align: 'left',
        //   customRender: text => ['二级商户', '手动'][text],
        //   onExport: text => ['二级商户', '手动'][text],
        //   filterMultiple: false,
        //   filters: [
        //     {
        //       text: '手动',
        //       value: '1'
        //     },
        //     {
        //       text: '二级商户',
        //       value: '0'
        //     }
        //   ]
        // },
        {
          dataIndex: 'jsStatus',
          title: '结算状态',
          type: 'badge',
          filters: [
            {
              text: '已结算',
              value: '1',
            },
            {
              text: '未结算',
              value: '0',
            },
          ],
          filterMultiple: false,
          onExport: (records) => {
            return ['未结算', '二级商户', '结算失败', '结算异常'][records]
          },
          render(data, records) {
            return {
              showDot: data ? true : false,
              name:
                records.jsType == '1'
                  ? '手动'
                  : {
                      0: '未结算',
                      1: '二级商户',
                      2: '结算失败',
                      3: '结算异常',
                    }[data] || '',
              color:
                {
                  0: '#e6ebf1',
                  1: '#ff9901',
                  2: '#ff9901',
                  3: '#ed4014',
                }[data] || '',
            }
          },
        },
        {
          dataIndex: 'cashOutStatus',
          title: '出金状态',
          type: 'badge',
          align: 'center',
          filters: [
            {
              text: '未出金',
              value: '0',
            },
            {
              text: '已出金',
              value: '1',
            },
            {
              text: '出金失败',
              value: '2',
            },
            {
              text: '出金异常',
              value: '3',
            },
          ],
          filterMultiple: false,
          onExport: (records) => {
            return ['未出金', '已出金', '出金失败', '出金异常'][records]
          },
          render(data, records) {
            return {
              showDot: data ? true : false,
              name:
                records.jsType == '1'
                  ? ''
                  : {
                      0: '未出金',
                      1: '已出金',
                      2: '出金失败',
                      3: '出金异常',
                    }[data] || '',
              color:
                {
                  0: '#e6ebf1',
                  1: '#ff9901',
                  2: '#ed4014',
                  3: '#ed4014',
                }[data] || '',
            }
          },
        },
        {
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '结算',
                type: 'pop',
                popTitle: '确认结算吗?',
                display: (records.jsStatus == '0' || records.jsStatus == '2' || records.jsStatus == '3') && this.handJsState == 1,
                onClick: () => {
                  api.command.edit
                    .call(this, {
                      url: '/jsOrder/batchJs',
                      params: { ids: [records.id] },
                      showMessage: false,
                    })
                    .then((e) => {
                      this.initJsOrder()
                      if (e.data) {
                        e.data.forEach((element) => {
                          Message[this.jsStatus[element.jsStatus].status](this.jsStatus[element.jsStatus].statusName)
                        })
                      } else {
                        Message.success('编辑成功')
                      }
                    })
                  // this.showPayType(records.id)
                },
              },
              {
                name: '出金',
                type: 'pop',
                popTitle: '确认出金吗?',
                display:
                  records.jsStatus == '1' &&
                  (records.cashOutStatus == '0' || records.cashOutStatus == '2' || records.cashOutStatus == '3'),
                onClick: () => {
                  api.command.edit
                    .call(this, {
                      url: '/jsOrder/batchCashOut',
                      params: { ids: [records.id] },
                      showMessage: false,
                    })
                    .then((e) => {
                      this.initJsOrder()
                      if (e.data) {
                        e.data.forEach((element) => {
                          Message[this.cashOutStatus[element.cashOutStatus].status](
                            this.cashOutStatus[element.cashOutStatus].statusName
                          )
                        })
                      } else {
                        Message.success('编辑成功')
                      }
                    })
                },
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    showPayType(ids) {
      apiTool.showModal({
        width: '400px',
        title: '支付方式',
        footer: false,
        form: [
          {
            type: 'button',
            label: '二级商户',
            labelCol: { span: 0 },
            wrapperCol: { span: 24 },
            props: {
              type: 'primary',
              on: {
                click: () => {
                  console.log('二级商户', ids)
                },
              },
            },
          },
          {
            type: 'button',
            label: '手动结算',
            labelCol: { span: 0 },
            wrapperCol: { span: 24 },
            props: {
              on: {
                click: () => {
                  console.log('手动结算', ids)
                },
              },
            },
          },
        ],
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量结算',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                // this.showPayType(idList)
                return api.command.edit
                  .call(this, {
                    url: '/jsOrder/batchJs',
                    params: { ids: idList },
                    showMessage: false,
                  })
                  .then((e) => {
                    if (e.data) {
                      this.initJsOrder()
                      e.data.forEach((element) => {
                        Message[this.jsStatus[element.jsStatus].status](this.jsStatus[element.jsStatus].statusName)
                      })
                    } else {
                      Message.success('编辑成功')
                    }
                  })
              },
            },
            {
              name: '批量出金',
              onClick: (data) => {
                let idList = data.map((e) => e.id)
                return api.command.edit
                  .call(this, {
                    url: '/jsOrder/batchCashOut',
                    params: { ids: idList },
                    showMessage: false,
                  })
                  .then((e) => {
                    if (e.data) {
                      this.initJsOrder()
                      e.data.forEach((element) => {
                        Message[this.cashOutStatus[element.cashOutStatus].status](
                          this.cashOutStatus[element.cashOutStatus].statusName
                        )
                      })
                    } else {
                      Message.success('编辑成功')
                    }
                  })
              },
            },
          ],
        },
      ]
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            let parmas = {
              ...data,
              shopId: this.$route.query.shopId,
            }
            downloadAction(`/api/jsOrder/jsOrderExport${getParams(parmas)}`, '结算.xlsx')
          },
        },
      ]
    },
    search(data) {
      let parmas = {
        ...data,
        shopId: this.$route.query.shopId,
      }
      getAction(`/jsOrder/mainBody/detail`, parmas).then((result) => {
        this.jsOrderObj = result.data || {}
        this.$forceUpdate()
      })
    },
  },
  render() {
    return (
      <div>
        <HomeTop data={this.getHomeTopDataConfig()} />
        <TemplateHeaderTable
          headerData={this.getHeader()}
          tableColumns={this.getColumns()}
          button={this.getButton()}
          rightButton={this.getRightButton()}
          records={this.records}
          filterRightButtonKey={['refresh', 'setting']}
          search={this.search}
        />
      </div>
    )
  },
}
</script>
<style lang="less" scoped>
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 15%;
  }
}
.product-order-item:nth-child(1)::after {
  content: '';
  display: block;
  width: 11px;
  height: 11px;
  color: #999999;
}
.product-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    height: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
.table-foot {
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(232, 234, 236, 1);
    span {
      width: 150px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #515a6e;
    }
  }
}
</style>
